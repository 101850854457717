import anime from "animejs";

import { inViewport } from "./utils";
import { $, $$ } from "@utils/dom";

const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.bg = $('.site-loader__bg', this.el);
    this.logoLetters = [...$$('.site-loader__logo__letter', this.el)].reverse();
  }

  init() {  
  }

  loaded() {
    // increment --module-delay css variable to each [data-module-delay] in viewport during initialization
    [ ...$$(`[data-module-delay]`) ].forEach((el, index) => {
      const isInViewport = inViewport(el);
      el.setAttribute('data-module-delay', isInViewport);
      if( isInViewport ) el.style.setProperty("--module-delay", `${index * 350 + 550}ms`);
    });
  }

  ready() {
    return new Promise(resolve => {
      anime({
        targets: this.logoLetters,
        translateY: '103%',
        duration: 750,
        delay: anime.stagger(50),
        easing: 'easeInOutCubic',
      });

      anime({
        targets: this.bg,
        scaleY: 0,
        duration: 1150,
        delay: ((this.logoLetters.length-1)*50),
        easing: 'easeInOutExpo',
        complete: resolve,
      });
    });
  }

  done() {
    // remove from DOM when completed
    this.el.parentNode.removeChild(this.el);

    this.el = null;
    this.bg = null;
    this.logoLetters = null;
  }
}

export default SiteLoader;
