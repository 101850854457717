import anime from "animejs";

import { inViewport } from "./utils";
import { $, $$ } from "@utils/dom";

import { getCurrentlocale } from "@core/locales"

const SELECTOR = "[data-site-transition]";


class SiteTransition {
  constructor() {
    this.el = $(SELECTOR);
    this.bg = $('.site-transition__bg', this.el);
  }

  exit() {
    this.el.classList.remove("pointer-events-none");

    return new Promise((resolve) => {
      anime({
        targets: this.bg,
        translateY: ['-101%', '0%'],
        duration: 950,
        easing: 'easeInOutExpo',
        complete: resolve,
      });

      this.el.classList.remove("visibility-hidden");
    });
  }

  entering({ html }) {
    //
    // get the alternate link for site-nav, important note: this works only for 2 languages
    //
    const parser = new DOMParser();
    const source = parser.parseFromString(html, "text/html");
    const alternateHreflangElement = $(`link[rel='alternate'][hreflang]:not([hreflang='${getCurrentlocale()}'])`, source);
    if(alternateHreflangElement) {
      this._alternate_hreflang = alternateHreflangElement.getAttribute('href');
      this._alternate_hreflocale = alternateHreflangElement.getAttribute('hreflang')
    }

    // increment --module-delay css variable to each [data-module-delay] in viewport during initialization
    return [ ...$$(`[data-module-delay]`) ].forEach((el, index) => {
      const isInViewport = inViewport(el);
      el.setAttribute('data-module-delay', isInViewport);
      if( isInViewport ) el.style.setProperty("--module-delay", `${index * 350 + 450}ms`);
    });
  }

  enter() {
    // find lang-item element
    const languageItemElement = $(`.site-nav .lang-item > a[hreflang*='${this._alternate_hreflocale}']`);

    // change its href value
    if(this._alternate_hreflang && languageItemElement) languageItemElement.setAttribute('href', this._alternate_hreflang);

    return new Promise((resolve) => {
      anime({
        targets: this.bg,
        translateY: ['0%', '101%'],
        duration: 1150,
        easing: 'easeInOutExpo',
        complete: () => {
          this.el.classList.add("visibility-hidden", "pointer-events-none");
          resolve();
        }
      });
    });
  }

  done() {
    this._alternate_hreflang = null;
    this._alternate_hreflocale = null;
  }

}

export default SiteTransition;
